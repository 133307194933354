<template lang="pug">
  include ../mixins
  +b.catalog-view
    ui-preloader(v-if="isLoad")
    renderer(
      v-if="response"
      :result="response"
      :class-render="classRenderer"
    )
    div(
      v-else
      :class="classRenderer"
    )
      slot(name="cards")
    pagination(
      v-if="paginationInfo.count > paginationInfo.per_page"
      :current="paginationInfo.page"
      :total="paginationInfo.count"
      :perPage="paginationInfo.per_page"
      :is-add-more="true"
      @changed="paginationChangeHandler"
    )
</template>

<script>
import {
  articlesPaginationResource,
  videoPaginationResource,
  setPaginationResource,
  stockPaginationResource,
  stockPaginationLabelResource,
  stockProductsPaginationResource,
} from '@services/pagination.service.js'
import { scrollUnlock, scrollLock } from '@utils/scroll'

export default {
  components: {
    pagination: () => import('@app/UiElements/Pagination'),
  },

  props: {
    resource: String,
    pagination: Object,
    slug: String,
    label: String,
    needPushState: {
      type: Boolean,
      default: false,
    },
    classRenderer: {
      type: String,
      default: 'g-row g-row--space_3xl-xl g-row--appearance_spaced',
    },
  },

  data() {
    return {
      response: '',
      resourceObject: {
        articles: {
          common: articlesPaginationResource,
        },
        videos: {
          common: videoPaginationResource,
        },
        set: {
          common: setPaginationResource,
        },
        stock: {
          common: stockPaginationResource,
          label: stockPaginationLabelResource,
        },
        stockProducts: {
          label: stockProductsPaginationResource,
        },
      },
      paginationInfo: {
        count: this.pagination.count,
        page: this.pagination.page,
        per_page: this.pagination.perPage,
      },
      isAddPage: false,
      isLoad: false,
    }
  },

  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },

  methods: {
    async getCards(page = 1, allowPushState = true) {
      this.isLoad = true

      const params = {
        page,
        label: this.label,
      }

      const resourceConfig = this.resourceObject[this.resource]
      const resource = this.label ? resourceConfig.label : resourceConfig.common

      const res = await resource.execute(params)

      if (this.isAddPage) {
        this.response += res.data
      } else {
        this.response = res.data
      }

      if (res.meta.perPage) {
        res.meta.per_page = res.meta.perPage

        delete res.meta.perPage
      }

      this.paginationInfo = res.meta

      if (allowPushState && this.needPushState) {
        this.pushHistory()
      }

      this.isLoad = false
    },

    pushHistory() {
      let path = this.slug

      if (this.label) {
        path += `${this.label}/`
      }

      if (1 < this.paginationInfo.page) {
        path += `page/${this.paginationInfo.page}/`
      }

      window.history.pushState(null, null, `${window.location.origin}${path}`)
    },

    async paginationChangeHandler(info) {
      this.isAddPage = info.isLoadMore

      this.paginationInfo.page = info.page

      scrollLock()

      if (!this.response && this.isAddPage) {
        await this.getCards(info.page - 1, false)
        await this.getCards(info.page)
      } else {
        await this.getCards(info.page)
      }

      scrollUnlock()

      this.isAddPage = false
    },
  },
}
</script>
