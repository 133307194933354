import { scrollUnlock, scrollLock } from '@utils/scroll'

export function categoryModal() {
  const modalButtons = document.querySelectorAll('.js-categories-trigger')

  if (!modalButtons.length) return

  const modal = document.querySelector('.js-category-modal')
  const closeItems = modal.querySelectorAll('.js-category-modal-close')
  const main = modal.querySelector('.js-category-main')
  const mainItems = modal.querySelectorAll('.js-category-item')
  const childs = modal.querySelectorAll('.js-category-childs')
  const backItems = modal.querySelectorAll('.js-category-modal-back')

  const sidebar = document.querySelector('.js-sidebar')
  const sidebarBlur = document.querySelector('.js-sidebar-blur')
  const navigationBlur = document.querySelector('.js-navigation-blur')

  modalButtons.forEach(b => {
    b.addEventListener('click', () => {
      const IS_DESKTOP = 1200 <= window.innerWidth

      if (IS_DESKTOP) return

      scrollLock()

      modal.classList.add('is-active')

      sidebar.classList.remove('is-active')
      sidebarBlur.classList.remove('is-active')
      navigationBlur.classList.remove('is-active')
    })
  })

  closeItems.forEach(item => {
    item.addEventListener('click', () => {
      modal.classList.remove('is-active')

      scrollUnlock()
    })
  })

  backItems.forEach(item => {
    item.addEventListener('click', () => {
      main.classList.add('is-active')

      childs.forEach(child => {
        child.classList.remove('is-active')
      })
    })
  })

  mainItems.forEach(item => {
    item.addEventListener('click', () => {
      const itemId = item.getAttribute('data-category')

      main.classList.remove('is-active')

      childs.forEach(child => {
        if (itemId === child.getAttribute('data-category')) {
          child.classList.add('is-active')
        }
      })
    })
  })
}
