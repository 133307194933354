<template lang="pug">
  include ../mixins
  validation-observer.relative(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    ui-preloader(v-if="isLoad")
    +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="email_phone|required"
          type="text"
          :input-label="_('Е-mail або номер телефону')"
          v-model="formdata.emailPhone"
          name="emailPhone"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          :input-label="_('Пароль')"
          name="password"
        )
        +b.ds-panel.--space_xl
          +e.element.--offset_top
            +b.A.ds-link.--size_sm.--color_main.--inline(
              :href="forgotPasswordPageUrl"
            ) {{ _("Забули пароль?") }}
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--justify_between.--align_center.--appearance_spaced
          +b.g-cell.g-cols.--6-xs
            +b.BUTTON.control-button(
              @click.prevent="validateBeforeSubmit()"
              :disabled="isLoad"
            )
              +e.element
                +e.SPAN.text {{ _('Увійти') }}
          +b.g-cell.g-cols
            +b.A.ds-link.--size_sm.--size_2sm-xl.--color_main.--weight_bold(
              :href="registrationPageUrl"
            ) {{ _("Зареєструватися") }}
      validation-provider(
        slim
        v-slot="{ errors }"
        name="nonFieldErrors"
      )
        +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
          +b.P.error-label {{ errors[0] }}
</template>

<script>
import { signInResource } from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'

export default {
  mixins: [FormMixin],

  data() {
    return {
      formdata: {
        emailPhone: '',
        password: '',
      },
      forgotPasswordPageUrl: window.forgotPasswordPageUrl,
      registrationPageUrl: window.registrationPageUrl,
    }
  },

  methods: {
    send(data) {
      return signInResource.execute({}, data).then(() => {
        const redirect = this.getRedirectUrl()

        if (redirect) {
          window.location = redirect
        } else {
          window.location = window.indexPageUrl
        }
      })
    },

    getRedirectUrl() {
      const params = new URLSearchParams(window.location.search)
      const redirect = params.get('next')

      return redirect
    },
  },
}
</script>
