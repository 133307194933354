<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    +b.subscription-form-common
      +e.field
        d-control-input(
          v-model="formdata.email"
          input-class="d-control-input--variant_1"
          rules="email|required"
          type="email"
          inputmode="email"
          name="email"
          :placeholder="labels.email"
        )
      +e.button
        +b.control-button.--variant_dark.--variant_6(@click.prevent="validateBeforeSubmit()")
          +e.element
            +e.SPAN.content {{ _("Підписатися") }}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { subscriptionResource } from '@services/common.service.js'

export default {
  mixins: [FormMixin],

  data() {
    return {
      formdata: {
        email: '',
      },
      labels: {
        email: this._('Ваш e-mail'),
      },
    }
  },

  methods: {
    send() {
      return subscriptionResource.execute({}, this.formdata)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
  },
}
</script>
