import { mapActions } from 'vuex'
import { createStore } from '@store'
import { isChromeOnWindows } from '@utils/utils'
import { createRouter } from './js/router'
import { createI18n } from './i18n'

export function createApp({ Vue, Router, Store, I18n }) {
  const router = createRouter({ Vue, Router })
  const i18n = createI18n({ Vue, I18n })
  const store = createStore({ Vue, Store })

  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],

    async created() {
      if (!window.isContentPage) {
        if (window.isAuthenticated) {
          await this.getUserData()
        }

        this.getCompareData()
      }

      this.checkBrowser()
    },

    methods: {
      ...mapActions('user', [
        'getUserData',
      ]),
      ...mapActions('compare', [
        'getCompareData',
      ]),

      checkBrowser() {
        const needAddChromeClass = isChromeOnWindows()

        if (needAddChromeClass) {
          document.querySelector('body').classList.add('is-chrome-windows')
        }
      },
    },
  })

  router.beforeEach((to, from, next) => {
    next()

    window.scrollTo(0, 0)
  })

  return { app, router, store, i18n }
}
