<template lang="pug">
include ../mixins
+b.product-search-card
  +e.A.image-wrapper(:href="item.link")
    picture
      +e.IMG.image(
        v-if="item.src"
        :src="item.src"
      )
      +e.IMG.image(
        v-else
        :src="productPlaceholder"
      )
  +e.content
    +e.stock
      +b.stock-label(v-if="item.isAvailable")
        +e.icon
          icon-checkmark
        +e.text
          +b.SPAN.ds-caption.--size_sm.--color_black {{ _("В наличии") }}
      +b.stock-label(v-else)
        +e.icon.--size_2
          icon-cancel
        +e.text
          +b.SPAN.ds-caption.--size_sm.--color_black {{ _("Нет в наличии") }}
    +e.title
      +b.A.ds-link.--color_dark.--size_sm(
        :href="item.link"
      ) {{ item.titleProd }}
    +e.footer
      +e.footer-part
        +e.price-wrapper(v-if="item.priceB")
          +e.price
            +b.g-row.--align_center.--space_xs.--appearance_spaced
              +b.g-cell
                +b.SPAN.ds-caption.--size_xs.--color_relief-4.--appearance_through.--lh_1 {{ item.priceB|prettifyPrice }} {{ _("₴") }}
              +b.g-cell
                +b.SPAN.ds-caption.--size_xxs.--color_red.--weight_bold.--lh_1 -{{ prettyDiscount }} %
          +e.price
            +b.SPAN.ds-caption.--size_md.--weight_bold.--lh_1 {{ item.priceA|prettifyPrice }} {{ _("₴") }}
        +e.price-wrapper(v-else)
          +b.SPAN.ds-caption.--size_md.--weight_bold.--lh_1 {{ item.priceA|prettifyPrice }} {{ _("₴") }}
      +e.footer-part(v-if="item.isAvailable")
        buy-action(
          :product-id="item.id"
        )
          template(#default="{ handle }")
            +b.single-icon.--variant_2.--variant_6.--size_1(@click.prevent="handle")
              icon-cart
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },

  data() {
    return {
      productPlaceholder: window.productPlaceholder,
    }
  },

  computed: {
    prettyDiscount() {
      return (this.item.discount).toString().slice(0, 4)
    },
  },
}
</script>
