<template lang="pug">
  include ../mixins
  +b.g-row.--space_md
    +b.g-cell
      +b.A.social-wrapper(
        :href="googleAuthUrl"
      )
        +e.PICTURE.icon-wrapper.--size_1
          +e.icon
            icon-google
    +b.g-cell
      +b.A.social-wrapper(
        :href="facebookAuthUrl"
      )
        +e.PICTURE.icon-wrapper.--size_1
          +e.icon
            icon-facebook
</template>

<script>
export default {
  data() {
    return {
      googleAuthUrl: window.googleAuthUrl,
      facebookAuthUrl: window.facebookAuthUrl,
    }
  },
}
</script>
