import Vue from 'vue'

Vue.directive('product-previews-slider', {
  bind: node => {
    let isFirstRender = true

    const slides = [...node.querySelectorAll('.js-preview-item')]
    const dots = [...node.querySelectorAll('.js-preview-dot')]
    const prev = node.querySelector('.js-preview-prev')
    const next = node.querySelector('.js-preview-next')
    const track = node.querySelector('.js-preview-wrapper')

    slides.forEach(item => {
      item.addEventListener('touchstart', () => {
        item.closest('.swiper-container').classList.add('swiper-no-swiping')
      })

      item.addEventListener('touchend', () => {
        item.closest('.swiper-container').classList.remove('swiper-no-swiping')
      })
    })

    const getNewScrollPosition = arg => {
      const maxScrollLeft = track.scrollWidth - track.clientWidth

      if ('forward' === arg) {
        const x = (track.scrollLeft + track.clientWidth) - 1

        return x <= maxScrollLeft ? x : 0
      }

      if ('backward' === arg) {
        const x = (track.scrollLeft - track.clientWidth) + 1

        return 0 <= x ? x : maxScrollLeft
      }

      if ('number' === typeof arg) {
        const x = arg * track.clientWidth

        return x
      }

      return 0
    }

    const navigate = direction => {
      track.scrollLeft = getNewScrollPosition(direction)
    }

    prev.addEventListener('click', () => {
      navigate('backward')
    })

    next.addEventListener('click', () => {
      navigate('forward')
    })

    dots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        navigate(index)
      })
    })

    const slideObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (isFirstRender) return

        if (entry.isIntersecting) {
          const slideIndex = Number(entry.target.dataset.slideindex) - 1

          dots.forEach(dot => {
            dot.classList.remove('is-active')
          })

          dots[slideIndex].classList.add('is-active')
        }
      })
      isFirstRender = false
    }, { root: track, threshold: 0.1 })

    slides.forEach(slide => {
      slideObserver.observe(slide)
    })
  },
})
