<template lang="pug">
  include ../mixins
  validation-observer.relative(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    ui-preloader(v-if="isLoad")
    +b.g-row.--appearance_spaced.--space_lg.--space_3xl-xl
      +b.g-cell.g-cols.--12-xs.--6-sm.--3-half-hd
        d-control-input(
          rules="email_phone|required"
          type="text"
          :input-label="_('Е-mail або номер телефону')"
          v-model="formdata.emailPhone"
          name="emailPhone"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm.--3-half-hd
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          :input-label="_('Пароль')"
          name="password"
        )
      +b.g-cell.g-cols.--12-xs.--5-half-hd
        +b.card.--variant_12
          +b.g-row.--appearance_spaced.--space_xl.--align_center
            +b.g-cell
              +b.P.ds-caption.--size_2xs.--size_sm-xl {{ _("Увійти за допомогою") }}
            +b.g-cell
              authentication-social-networks
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--align_center.--justify_between-till-sm.--appearance_spaced.--space_2xl
          +b.g-cell.g-cols.--auto-xs.--6-sm.--3-half-hd
            +b.BUTTON.control-button.--variant_bordered(
              @click.prevent="validateBeforeSubmit()"
              :disabled="isLoad"
            )
              +e.element
                +e.SPAN.text {{ _('Увійти') }}
          +b.g-cell.g-cols.--narrow-xs.--3-half-hd
            +b.A.ds-link.--size_2xs.--size_sm-xl.--color_main(
              :href="forgotPasswordPageUrl"
            ) {{ _("Забули пароль?") }}
      validation-provider(
        slim
        v-slot="{ errors }"
        name="nonFieldErrors"
      )
        +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
          +b.P.error-label {{ errors[0] }}
</template>

<script>
import { signInResource } from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'

export default {
  mixins: [FormMixin],

  data() {
    return {
      formdata: {
        emailPhone: '',
        password: '',
      },
      forgotPasswordPageUrl: window.forgotPasswordPageUrl,
    }
  },

  methods: {
    send(data) {
      return signInResource.execute({}, data).then(() => {
        const redirect = this.getRedirectUrl()

        if (redirect) {
          window.location = redirect
        } else {
          window.location = window.indexPageUrl
        }
      })
    },

    getRedirectUrl() {
      const params = new URLSearchParams(window.location.search)
      const redirect = params.get('next')

      return redirect
    },
  },
}
</script>
