import PrivacyPolicy from './PrivacyPolicy'
import Login from './Login'
import LoginOrder from './LoginOrder'
import Registration from './Registration'
import RegistrationConfirm from './RegistrationConfirm'
import PasswordForgot from './PasswordForgot'
import PasswordForgotConfirm from './PasswordForgotConfirm'
import PasswordSet from './PasswordSet'
import EmailNotSet from './EmailNotSet'
import SocialNetworks from './SocialNetworks'

export default function install(Vue) {
  Vue.component('authentication-privacy-policy', PrivacyPolicy)
  Vue.component('authentication-login', Login)
  Vue.component('authentication-login-order', LoginOrder)
  Vue.component('authentication-registration', Registration)
  Vue.component('authentication-registration-confirm', RegistrationConfirm)
  Vue.component('authentication-password-forgot', PasswordForgot)
  Vue.component('authentication-password-forgot-confirm', PasswordForgotConfirm)
  Vue.component('authentication-password-set', PasswordSet)
  Vue.component('authentication-email-not-set', EmailNotSet)
  Vue.component('authentication-social-networks', SocialNetworks)
}
