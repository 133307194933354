<script>
import { openConfirmModal } from '@utils/submit'
import { deleteTrackResource } from '@services/common.service.js'

export default {
  props: {
    productId: Number,
    isActive: [String, Boolean],
  },

  data() {
    return {
      isActiveLocal: this.isActive,
    }
  },

  methods: {
    addTrack() {
      this.$modal.show(() => import('@app/Modals/Product/Availability'), {
        component: this.componentName,
        objectItem: {
          productId: this.productId,
          callback: this.successCallback,
        },
      })
    },

    successCallback() {
      this.isActiveLocal = true

      this.$emit('update')
    },

    async deleteTrack() {
      const title = this._('Ви впевнені, що хочете перестати слідкувати за наявністю?')

      await openConfirmModal({ title }, this)

      const formdata = { idProduct: this.productId }

      deleteTrackResource.execute({}, formdata).then(() => {
        this.$emit('update')

        this.isActiveLocal = false
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      addTrack: this.addTrack,
      deleteTrack: this.deleteTrack,
      isActive: this.isActiveLocal,
    })
  },
}
</script>
